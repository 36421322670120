<template>
    <div class="mbappdownload">
        <div class="bigbox">
            <div class="up">
                <div class="logo"><a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.xmamt.hhbb"><img src="../assets/mobile/header/logo2@2x.png"></a></div>
                <a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.xmamt.hhbb"><div class="ercode"></div></a>
                <div class="txt"><a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.xmamt.hhbb">点击或扫码立即下载</a></div>
            </div>
            <div class="down">
                <img src="../assets/mobile/guanbi@2x.png" @click="close()">
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Appdownload",
  data(){
      return{

      }
  },
  created(){

  },
  mounted(){
  },
  methods:{
      close(){
          this.$router.go(-1)
      }
  }
};
</script>

<style lang="less" scoped>
@import "~@/style/index.less";
.mbappdownload{
    display: none;
}
@c : #1B1B1B;
.less_mbappdownload(@number){
    .mbappdownload{
        position: fixed;
        top: 0;
        z-index: 999;
        display: block;
        background: rgba(141, 141, 141, 0.5);
        width: 100%;
        height: 100%;
        .felx_center();
        .bigbox{
           height: (703px / @number); 
           width: (560px / @number);
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           .up{
               width: (560px / @number);
               height: (600px / @number);
               background: #ffffff;
               border-radius: 20px;
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               .logo{
                   width: (355px / @number);
                   height: (84px / @number);
                   img{
                       width: 100%;
                       height: 100%;
                   }
               }
               .ercode{
                   width: (310px / @number);
                   height: (310px / @number);
                   background: url(../assets/mobile/appcode.png) no-repeat center;
                   background-size: 100%;
                   margin: (27px / @number) 0 (39px / @number) 0;
               }
               .txt{
                    font-size: (30px / @number);
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: (32px / @number);
               }
           }
           .down{
               .felx_center();
               align-items: flex-end;
               img{
                   width: (58px / @number);
                   height: (58px / @number);
               }
           }
        }
    }
}
@media screen and (max-width: 1200px) {
  .less_mbappdownload(2);
}
</style>